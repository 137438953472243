<template>
  <v-container>
    <v-toolbar flat class="mb-4">
      <InventoryDeviceTypePicker
        hide-details
        v-model="filter.deviceType"
        label="Typ"
      />
      <v-spacer class="mx-2" />
      <RoomPicker hide-details v-model="filter.location" label="Standort" />
      <v-spacer class="mx-2" />
      <InventoryBrandModelPicker
        hide-details
        v-model="filter.brandModel"
        label="Modell"
      />
      <v-spacer class="mx-2" />
      <InventoryCompanyPicker
        hide-details
        v-model="filter.company"
        label="Firma"
      />
      <v-spacer />
      <v-btn icon @click="getData">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        :to="{ path: 'devices/0/new' }"
        fab
        small
        depressed
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="itemsFiltered"
        :items-per-page="15"
        :loading="loading"
        @current-items="currentItems"
        :item-class="() => 'clickable'"
        @click:row="(item) => showDevice(item.id)"
      >
        <template v-slot:item.macAddress="{ item }">
          <v-chip label small v-if="item.macAddressLan">
            <v-icon left>mdi-ethernet</v-icon>{{ item.macAddressLan }}
          </v-chip>
          <v-chip label small v-if="item.macAddressWlan">
            <v-icon left>mdi-wifi</v-icon>{{ item.macAddressWlan }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
import InventoryBrandModelPicker from '@/components/inventory/BrandModelPicker';
import InventoryDeviceTypePicker from '@/components/inventory/DeviceTypePicker';
import InventoryCompanyPicker from '@/components/inventory/CompanyPicker';
import RoomPicker from '@/components/RoomPicker';

import { bus } from '@/services/bus.service';
import { formatDate, formatTime } from 'common/utils/date.js';

export default {
  name: 'Devices',
  currentDeviceId: 0,
  props: ['search'],
  components: {
    InventoryBrandModelPicker,
    InventoryDeviceTypePicker,
    InventoryCompanyPicker,
    RoomPicker,
  },
  data: () => ({
    headers: [
      { text: 'Typ', value: 'deviceType.description', sortable: false },
      { text: 'Standort', value: 'location.code' },
      { text: 'Modell', value: 'brandModel.description' },
      { text: 'Name', value: 'name' },
      { text: 'SN', value: 'serialNumber' },
      {
        text: 'MAC',
        value: 'macAddress',
      },
      { text: 'Kommentar', value: 'comment' },
      { text: '', value: 'actions', sortable: false },
    ],
    filter: {
      location: null,
      brandModel: null,
      deviceType: null,
      company: null,
    },
    items: [],
    itemsShown: [],
    loading: false,
  }),
  computed: {
    itemsFiltered() {
      return this.items.filter((item) => {
        if (this.filter.location) {
          if (!item.location) {
            return false;
          }
          if (this.filter.location.id !== item.location.id) {
            return false;
          }
        }
        if (this.filter.brandModel) {
          if (!item.brandModel) {
            return false;
          }
          if (this.filter.brandModel.id !== item.brandModel.id) {
            return false;
          }
        }
        if (this.filter.deviceType) {
          if (!item.deviceType) {
            return false;
          }
          if (this.filter.deviceType.id !== item.deviceType.id) {
            return false;
          }
        }
        if (this.filter.company) {
          if (!item.merchant && !item.service) {
            return false;
          }
          if (
            (!item.merchant || this.filter.company.id !== item.merchant.id) &&
            (!item.service || this.filter.company.id !== item.service.id)
          ) {
            return false;
          }
        }
        return (
          !this.search ||
          (item.name &&
            item.name.toLowerCase().includes(this.search.toLowerCase())) ||
          (item.serialNumber &&
            item.serialNumber
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (item.macAddressWlan &&
            item.macAddressWlan
              .toLowerCase()
              .includes(this.search.toLowerCase())) ||
          (item.macAddressLan &&
            item.macAddressLan
              .toLowerCase()
              .includes(this.search.toLowerCase()))
        );
      });
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        localStorage.setItem(
          'inventoryDevicesFilter',
          JSON.stringify(this.filter)
        );
      },
    },
  },
  methods: {
    currentItems(els) {
      this.itemsShown = els;
    },
    formatDate,
    formatTime,
    getIcon(value) {
      if (value) return 'mdi-check';
      return 'mdi-close';
    },
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: 'inventory/device' });
      this.loading = false;
    },
    keydown(event) {
      if (this.$route.name !== 'InventoryDevice') return;
      if (event.isComposing || event.key === 'ArrowRight') {
        this.nextDevice();
      } else if (event.isComposing || event.key === 'ArrowLeft') {
        this.previousDevice();
      }
    },
    nextDevice() {
      let index = this.itemsShown.findIndex(
        (el) => el.id === this.currentDeviceId
      );
      let item = this.itemsShown[index + 1];
      if (item) {
        this.showDevice(item.id);
      } else {
        this.$root.showError('Ende der Tabelle!');
      }
    },
    previousDevice() {
      let index = this.itemsShown.findIndex(
        (el) => el.id === this.currentDeviceId
      );
      let item = this.itemsShown[index - 1];
      if (item) {
        this.showDevice(item.id);
      } else {
        this.$root.showError('Start der Tabelle!');
      }
    },
    showDevice(id) {
      this.currentDeviceId = id;
      this.$router.push({
        name: 'InventoryDevice',
        params: { id: id },
      });
    },
  },

  beforeRouteUpdate(to, from, next) {
    if (to.params.forceReload) {
      this.getData();
    }
    next();
  },
  async created() {
    if (localStorage.getItem('inventoryDevicesFilter')) {
      this.filter = JSON.parse(localStorage.getItem('inventoryDevicesFilter'));
    }
    await this.getData();
    bus.$on('nextDevice', () => {
      this.nextDevice();
    });
    bus.$on('previousDevice', () => {
      this.previousDevice();
    });
    window.removeEventListener('keydown', this.keydown);
    window.addEventListener('keydown', this.keydown);
  },
};
</script>
